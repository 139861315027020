/*
 * @Auth: linjituan
 * @Date: 2022-10-19 15:19:51
 * @LastEditors: linjituan linjituan@revolution.com
 * @LastEditTime: 2023-11-14 11:58:19
 */
import request from '@/utils/request'

// 查询阿里云音视频摄像头设备列表
export function listCameraDevice(query) {
  return request({
    url: '/iot/cameraDevice/list',
    method: 'get',
    params: query
  })
}

// 查询阿里云音视频摄像头设备详细
export function getCameraDevice(id) {
  return request({
    url: '/iot/cameraDevice/' + id,
    method: 'get'
  })
}

// 新增阿里云音视频摄像头设备
export function batchRegisterDevice(data) {
  return request({
    url: '/iot/cameraDevice/batchRegisterDevice?count=' + data.count,
    method: 'POST'
  })
}

// 删除阿里云音视频摄像头设备
export function delCameraDevice(id) {
  return request({
    url: '/iot/cameraDevice/' + id,
    method: 'delete'
  })
}

// 获取rtc设备信息
export function realTimeVideo(query) {
  return request({
    url: '/iot/cameraDevice/rtc/real-time-video',
    method: 'get',
    params: query
  })
}

// web申请一个rtc设备
export function webApplyDevice(query) {
  return request({
    url: '/iot/cameraDevice/rtc/web-apply-device',
    method: 'get',
    params: query
  })
}

// web归还一个rtc设备
export function webReturnDevice(query) {
  return request({
    url: '/iot/cameraDevice/rtc/web-return-device',
    method: 'get',
    params: query
  })
}

// web归还车辆所有临时rtc设备
export function webReturnDeviceVehicleId(query) {
  return request({
    url: '/iot/cameraDevice/rtc/web-return-device-vehicleId',
    method: 'get',
    params: query
  })
}

// web归还一个车辆rtc设备
export function vehicleReturnDevice(query) {
  return request({
    url: '/iot/cameraDevice/rtc/vehicle-return-device',
    method: 'get',
    params: query
  })
}

// 获取rtmp实时视频拉流的地址
export function rtmpRealTimeVideo(query) {
  return request({
    url: '/iot/cameraDevice/rtmp/real-time-video',
    method: 'get',
    params: query
  })
}

// 中控登录车辆 如果data.result=true，表示可以遥控，如果data.result=false，表示该车辆正在被其他人遥控中，不可多人同时遥控。
export function vehicleLogin(data) {
  return request({
    url: `/vehicle/cmd/${data.vehicleId}/command/login`,
    method: 'post',
    data: data
  })
}

// 检查在阿里云上已注册的设备，同步数据
export function syncRegisterDevice(query) {
  return request({
    url: '/iot/cameraDevice/syncRegisterDevice',
    method: 'get',
    params: query
  })
}

// 检查在阿里云上已注册的设备，同步数据
export function syncRegisterDeviceByApplyId(query) {
  return request({
    url: '/iot/cameraDevice/syncRegisterDeviceByApplyId',
    method: 'get',
    params: query
  })
}

// 获取rsa私钥
export function getPrivateKey(query) {
  return request({
    url: '/iot/cameraDevice/getPrivateKey',
    method: 'get',
    params: query
  })
}

// brtc用户心跳
export function brtcHeartbeat(query) {
  return request({
    url: '/iot/cameraDevice/rtc/brtc-heartbeat',
    method: 'get',
    params: query
  })
}
