<!--
 * @Auth: linjituan
 * @Date: 2022-10-26 16:52:29
 * @LastEditors: linjituan linjituan@revolution.com
 * @LastEditTime: 2023-03-16 15:48:47
-->
<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="申请个数" prop="count">
        <a-input v-model="form.count" placeholder="请输入申请个数" />
      </a-form-model-item>
      <!-- <a-button type="primary" style="margin-bottom: 30px" :loading="syncLoading" @click="syncRegisterDevice">
        同步数据
      </a-button> -->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="btnLoading" @click="submitForm"> 保存 </a-button>
          <a-button type="dashed" @click="cancel"> 取消 </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getCameraDevice, batchRegisterDevice, syncRegisterDeviceByApplyId } from '@/api/iot/cameraDevice'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        count: ''
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {},
      btnLoading: false,
      syncLoading: false,
      reloadFlag: 2
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        count: ''
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getCameraDevice(id).then((response) => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    syncRegisterDevice(applyId) {
      this.syncLoading = true
      syncRegisterDeviceByApplyId({
        applyId: applyId
      })
        .then((res) => {
          if (res.data && res.data.registerSuccess < this.form.count && this.reloadFlag > 0) {
            setTimeout(() => {
              this.syncRegisterDevice(applyId)
            }, 2000)
            this.reloadFlag--
          } else {
            this.syncLoading = false
            this.$message.success(
              res.data.registerSuccess < this.form.count ? '同步失败，请稍后点击同步数据按钮进行同步' : res.msg,
              3
            )
            this.open = false
            this.btnLoading = false
            this.reloadFlag = 2
            this.$emit('ok')
          }
        })
        .catch(() => {
          this.btnLoading = false
          this.syncLoading = false
        })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.btnLoading = true
          this.form.count = parseInt(this.form.count)
          batchRegisterDevice(this.form)
            .then((response) => {
              // this.btnLoading = false
              if (response.data && response.data.registerSuccess < this.form.count) {
                this.$message.success('申请成功并同步数据中，请稍等。。。', 3)
                setTimeout(() => {
                  this.syncRegisterDevice(response.data.applyId)
                }, 2000)
              } else {
                this.$message.success('申请成功', 3)
                this.open = false
                this.btnLoading = false
                this.$emit('ok')
              }
              // this.open = false
            })
            .catch(() => {
              this.btnLoading = false
            })
        } else {
          return false
        }
      })
    }
  }
}
</script>
